<template>
    <div class="main-hospital-panel">
        <div class="main-hospital-panel-top">
            <p class="main-hospital-panel-top-title">切换机构</p>
            <el-input class="main-hospital-panel-top-input" placeholder="请输入机构名称搜索" v-model="keyword">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
        </div>
        <div class="main-hospital-panel-content" style="flex:1">
            <div ref="list" class="main-hospital-panel-content-list">
                <p :id="'item' + item.hospitalid" class="main-hospital-panel-content-list-item"
                    :class="{ 'main-hospital-panel-content-list-item-selected': item.hospitalid == selectedHospital.hospitalid }"
                    v-for="(item, index) in dataArray" :key="index" @click="onSelected(item)">{{
                        item.name
                    }}</p>
            </div>
        </div>
        <div class="main-hospital-panel-bottom">
            <button class="main-hospital-panel-bottom-cancel" @click="onCancel">取消</button>
            <button class="main-hospital-panel-bottom-cancel" @click="onGoTop">回到顶部</button>
            <div style="flex: 1;"></div>
            <button class="main-hospital-panel-bottom-confirm" @click="onConfirm">确定</button>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "isShowDrawer",
        event: "onisShowDrawerChaneed"
    },

    prop: {
        isShowDrawer: Boolean,

    },
    props: {
        selectedModuleIndex: Number,
    },
    data() {
        return {
            dataSource: [],
            keyword: "",
            selectedHospital: undefined,
        }
    },

    computed: {
        initialSelectedHospital() {
            return this.$store.state.selectedHospital;
        },

        dataArray() {
            if (this.keyword) {
                return this.dataSource.filter((item) => {
                    return item.name.includes(this.keyword)
                })
            } else {
                return this.dataSource
            }
        }
    },

    mounted() {
        this.selectedHospital = this.initialSelectedHospital
        this.getHospitalList()
    },

    methods: {
        onSelected(selectedHospital) {

            this.selectedHospital = selectedHospital
        },

        async getHospitalList() {
            try {
                const params = {
                    parentId: this.$store.state.accountInfo.id
                }
                const response = await this.$api.getHospitalList(params)

                const rawDataArray = response.map((item) => {
                    return {
                        name: item.hospitalName,
                        hospitalid: item.id
                    }
                });
                this.dataSource = [{ name: "全部", hospitalid: null }].concat(rawDataArray)
                //跳转到选中医院
                this.$nextTick(() => {
                    const selectedHospitalID = this.selectedHospital.hospitalid
                    document.getElementById(`item${selectedHospitalID}`).scrollIntoView({ block: 'center' });
                })
            } catch (error) {
                this.$toast.showRed(error);
            }
        },

        onCancel() {
            this.$emit("onisShowDrawerChaneed", false)
        },
        onGoTop() {
            const listContainer = this.$refs.list;
            if (listContainer) {
                listContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },
        onConfirm() {
            if (this.selectedModuleIndex == 8 && this.selectedHospital.hospitalid == null) {
                this.$toast.showRed('机构不能设置为全部');
                return
            }
            this.$store.commit("saveSelectedHospital", this.selectedHospital)
            this.$emit("onisShowDrawerChaneed", false)
        }
    },

    watch: {

    }
}
</script>

<style lang="scss" scoped>
.main-hospital-panel {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: rem(40);
    display: flex;
    flex-direction: column;


    &-top {
        padding-bottom: 10px;

        &-title {
            color: #444C72;
            font-size: rem(24);
            line-height: rem(33);
        }

        &-input {
            font-size: 14px;

            /deep/ .el-input__inner {
                height: 32px;
                border: none;
                background-color: #F7F7F7;
                border-radius: 10px;
                margin-top: 20px;
            }

            /deep/.el-input__prefix {
                top: 10px !important;
            }
        }

    }

    &-content {
        flex: 1 1 auto;
        height: 0;
        overflow-y: auto;



        &-list {
            margin-top: 27px;

            &-item {
                color: #888888;
                font-size: 14px;
                border-radius: rem(5);
                height: rem(36);
                display: flex;
                align-items: center;
                justify-content: center;

                &-selected {
                    color: white;
                    background-color: #325FAC;
                }
            }
        }
    }

    &-bottom {
        padding-top: 10px;
        display: flex;
        align-items: center;


        &-cancel {
            flex-shrink: 0;
            background-color: #F7F7F7;
            color: #444C72;
            font-size: rem(14);
            border-radius: rem(5);
            width: rem(72);
            height: rem(44);
            margin-right: rem(20);
        }

        &-confirm {
            background-color: #325FAC;
            color: white;
            font-size: rem(14);
            border-radius: rem(5);
            width: rem(243);
            height: rem(44);
        }
    }

}
</style>