import Vue, { computed } from "vue";
import Vuex from "vuex";

import "@js/constants.js";
import { CacheTool } from "@js/cache-tool.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // accountInfo: CacheTool.getAccountInfo(),
    accountInfo: undefined,
    isMainLeftSlim: false,
    selectedModuleIndex: 0,
    selectedHospital: { name: "全部", hospitalid: null },
    deviceInfo: undefined,
    loadingShow: false
  },

  getters: {
    isSeniorAdministrator(state) {
      return state.accountInfo?.roleName == '高级系统管理员'
    },

    isSeniorAdministratorOrOperationMember(state) {
      return state.accountInfo?.roleName == '高级系统管理员' || state.accountInfo?.roleName ==  '运营专员'
    }

  },

  mutations: {
    //isMainLeftSlim
    saveIsMainLeftSlim(state, isMainLeftSlim) {
      state.isMainLeftSlim = isMainLeftSlim;
      CacheTool.saveStoreState(state) //每次操作保存一下state备份。供App页面刷新时重新载入
    },

    //selectedModuleIndex
    saveSelectedModuleIndex(state, selectedModuleIndex) {
      state.selectedModuleIndex = selectedModuleIndex;
      CacheTool.saveStoreState(state)
    },

    //hospital
    saveSelectedHospital(state, selectedHospital) {
      state.selectedHospital = selectedHospital;
      CacheTool.saveStoreState(state)
    },

    //accountInfo
    saveAccountInfo(state, accountInfo) {
      state.accountInfo = accountInfo;
      CacheTool.saveStoreState(state)
    },

    removeAccountInfo(state) {
      state.accountInfo = undefined;
      CacheTool.saveStoreState(state)
    },

    //deviceInfo
    saveDeviceInfo(state, deviceModel) {
      state.deviceInfo = deviceModel;
      CacheTool.saveStoreState(state)
    },

    removeDeviceInfo(state) {
      state.deviceInfo = undefined;
      CacheTool.saveStoreState(state)
    }
  },
  actions: {},
  modules: {}
});
