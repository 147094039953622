export function computeBMI(height, weight, decimal = 2) {
    const heightNumber = Number(height);
    const weightNumber = Number(weight);
    if (isNaN(heightNumber) || isNaN(weightNumber) || heightNumber == 0 || weightNumber == 0) {
        return "";
    } else {
        const rawBMI = weightNumber / Math.pow(heightNumber / 100, 2);
        const bmi = rawBMI.toFixed(decimal);
        return `${bmi}`;
    }
}

export function computeSex(gender) {
    switch (gender) {
        case "男":
            return 1
        case "女":
            return 2
        default:
            return 0
    }
}

export function countDown({
    remainSeconds: remainSeconds,
    trigger: trigger,
    over: over
}) {
    let timer = setInterval(() => {
        remainSeconds--;
        trigger(remainSeconds);
        if (remainSeconds == 0) {
            clearInterval(timer);
            over();
        }
    }, 1000);
}
