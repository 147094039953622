<template>

  <div v-if="isShow" class="alert z-index-top">
    <div class="alert-card">
      <div class="alert-card-top">
        <p class="alert-card-top-title">{{ title }}</p>
        <p class="alert-card-top-info">{{ info }}</p>
      </div>
      <div class="alert-card-bottom">
        <button class="alert-card-bottom-cancel" @click="onCancelClick">取消</button>
        <button class="alert-card-bottom-confirm" @click="onConfirmClick">
          确认
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isShow: false,
      title: undefined,
      info: undefined,
      confirmEvent: undefined,
    };
  },

  methods: {
    show: function (title, info, confirmEvent) {
      this.isShow = true;
      this.title = title;
      this.info = info;
      this.confirmEvent = confirmEvent;
    },

    onCancelClick: function () {
      this.isShow = false;
    },

    onConfirmClick: function () {
      this.isShow = false;
      this.confirmEvent();
    },
  },
};
</script>

<style lang="scss" scoped>

.alert {
  background-color: rgba($color: #666666, $alpha: 0.2);
  position: fixed;
  top: rem(0);
  left: rem(0);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-card {
    background-color: white;
    border-radius: rem(3);
    box-shadow: rem(0) rem(0) rem(15) rem(0) rgba(black, 0.1);
    width: rem(260);

    &-top {
      width: 100%;
      height: rem(150);
      box-sizing: border-box;
      padding-top: rem(19);
      padding-left: rem(20);

      &-title {
        color: #4d4f5c;
        font-size: rem(18);
        line-height: rem(24);
      }

      &-info {
        color: rgba($color: #4d4f5c, $alpha: 0.5);
        font-size: rem(14);
        line-height: rem(20);
        margin-top: rem(8);
      }
    }

    &-bottom {
      width: 100%;
      height: rem(50);
      display: flex;

      &-cancel {
        color: white;
        background-color: #0D3F77;
        font-size: rem(16);
        border-bottom-left-radius: rem(3);
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-confirm {
        color: white;
        background-color: #325FAC;
        font-size: rem(16);
        border-bottom-right-radius: rem(3);
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>