<template>
  <div class="pop-view z-index-top" @click="onMaskClick">
    <div class="pop-view-card"><slot @click="onCardClick($event)"></slot></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reference: undefined,
    };
  },

  mounted() {
    //为slot添加点击事件
    if (this.$slots.default) {
      this.reference = this.$slots.default[0].elm;
    }
    // console.log(this.$slots.default[0].elm);
    if (this.reference) {
      this.reference.addEventListener("click", this.onCardClick, false);
    }
  },

  beforeDestroy() {
    if (this.reference) {
      this.reference.removeEventListener("click", this.onCardClick, false);
    }
  },

  methods: {
    onCardClick: function (event) {
      event.stopPropagation(); //停止穿透
    },

    onMaskClick: function () {
      this.onCloseClick();
    },

    onCloseClick: function () {
      this.$emit("onCloseClick");
    },
  },
};
</script>

<style lang="scss" scoped>

.pop-view {
  background-color: rgba($color: #666666, $alpha: 0.2);
  position: fixed;
  top: rem(0);
  left: rem(0);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

    &-card {
      background-color: white;
      border-radius: rem(4);
    }
}
</style>