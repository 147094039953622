<template>
  <div
    class="toast "
    :class="[color, [isBelow ? 'below' : 'above']]"
    v-if="isShow"
  >
    <img class="toast-left-icon" :src="leftIcon" />
    <p class="toast-content">{{ content }}</p>
    <p v-if="link.length != 0" class="toast-link" @click="onLinkClick">
      {{ link }}
    </p>
    <img
      v-if="isManualDisappear"
      class="toast-right-icon"
      src="@imgs/toast-delete-gray.png"
      @click="hide"
    />
  </div>
</template>
 
<script>
import store from "../../store";
export default {
  data() {
    return {
      isShow: false,
      color: "red", //red green blue
      isBelow: false,
      isMainLeftSlim: false,
      isManualDisappear: false,
      content: "",
      link: "",
      linkEvent: undefined,
      times: {},
    };
  },

  computed: {
    leftIcon: function () {
      switch (this.color) {
        case "red":
          return require("@imgs/toast-delete-red.png");
        case "green":
          return require("@imgs/toast-check.png");
        default:
          return require("@imgs/toast-alert.png");
      }
    },
  },

  methods: {
    onLinkClick: function () {
      this.linkEvent();
      this.hide();
    },

    loginShowRed(content) {
      this._show({
        color: "red",
        content,
        link: "",
        linkEvent: undefined,
        isBelow: true,
        isManualDisappear: true,
      });
    },

    loginShowGreen(content) {
      this._show({
        color: "green",
        content,
        link: "",
        linkEvent: undefined,
        isBelow: true,
        isManualDisappear: false,
      });
    },

    showGreenLink(content, link, linkEvent) {
      this._show({
        color: "green",
        content,
        link,
        linkEvent,
        isBelow: false,
        isManualDisappear: false,
      });
    },

    showGreen(content) {
      this._show({
        color: "green",
        content,
        link: "",
        linkEvent: undefined,
        isBelow: false,
        isManualDisappear: false,
      });
    },

    showRed(content) {
      this._show({
        color: "red",
        content,
        link: "",
        linkEvent: undefined,
        isBelow: false,
        isManualDisappear: false,
      });
    },

    _show({ color, content, isBelow, isManualDisappear, link, linkEvent }) {
      this.color = color;
      this.content = content;
      this.isBelow = isBelow;
      this.isMainLeftSlim = store.state.sidebarStatus;
      this.isManualDisappear = isManualDisappear;
      this.link = link;
      this.linkEvent = linkEvent;
      this.isShow = true;
      //定义了显示时长
      if (!this.isManualDisappear) {
        //重新计时
        if (this.times != null) clearTimeout(this.times);
        this.times = setTimeout(() => {
          this.content = "";
          this.isShow = false;
        }, 2000);
      }
    },

    hide() {
      //清除计时
      if (this.times != null) clearTimeout(this.times);
      this.isShow = false;
    },
  },
};
</script>
 
<style lang="scss" scoped>


.toast {
  @include z-index-max;
  color: #505659;
  font-size: rem(14);
  line-height: rem(14);
  border-radius: rem(3);
  min-width: rem(150);
  height: rem(40);
  box-sizing: border-box;
  padding-left: rem(16);
  padding-right: rem(15);
  position: fixed;
  left: 50%;
  display: flex;
  align-items: center;

  &-left-icon {
    width: rem(14);
    height: rem(14);
  }

  &-content {
    margin-left: rem(10);
  }

  &-link {
    color: #325FAC;
    text-decoration: underline;
  }

  &-right-icon {
    width: rem(9);
    height: rem(9);
    margin-left: rem(12);
  }
}

.above {
  top: 50%;
}

.below {
  top: rem(640);
  left: 50%;
  transform: translate(-50%, -50%);
}

.red {
  background-color: #ffeee6;
  border: rem(1) solid #ffddcc;
}

.green {
  background-color: #e6faf0;
  border: rem(1) solid #ccf5e0;
}

.blue {
  background-color: #e6f7ff;
  border: rem(1) solid #91d5ff;
}
</style>
