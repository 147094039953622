<template>
  <div class="main">
    <main-left-bar :isMainLeftSlim="isMainLeftSlim" :moduleArray="moduleArray"
      :selectedModuleIndex="selectedModuleIndex" @onModuleItemClick="onModuleItemClick"></main-left-bar>
    <div class="main-right">
      <div class="main-right-container">
        <main-right-top-bar :isMainLeftSlim="isMainLeftSlim" @onDisplayIconClick="onDisplayIconClick"
          @onChangePasswordClick="onChangePasswordClick" @onLogoutClick="onLogoutClick"
          :selectedModuleIndex="selectedModuleIndex"></main-right-top-bar>
        <main-right-middle-bar v-show="selectedModule.title != '首页'"
          :title="selectedModule.title"></main-right-middle-bar>
        <div class="main-right-container-bottom">
          <component class="main-right-container-bottom-content" :is="selectedModule.name"
            @onRecycleSuccessEditClick="onRecycleSuccessEditClick" @onAddUserClick="onDeviceManageAddUserClick"
            @onRecycleDeviceClick="onDeviceManageRecycleDeviceClick" @onHomeAddUserClick="onHomeAddUserClick"
            @onHomeRecycleDeviceClick="onHomeRecycleDeviceClick"></component>
        </div>
      </div>
    </div>
    <el-dialog class="main-dialog" :visible.sync="isShowChangePasswordAlert" :showClose=false>
      <change-password-alert @onIsShowChanged="changeIsShowChanged" @changePassword="isChangePassword" />
    </el-dialog>

    <el-dialog class="main-dialog" :visible.sync="isShowWeakPasswordAlert" :showClose=false>
      <weak-password-alert @onIsShowChanged="weakIsShowChanged" @onLogoutClick="onLogoutClick" />
    </el-dialog>
  </div>
</template>

<script>
import MainLeftBar from "@c/main/main-left-bar.vue";
import MainRightTopBar from "@c/main/main-right-top-bar.vue";
import MainRightMiddleBar from "@c/main/main-right-middle-bar.vue";
import { CacheTool } from "@js/cache-tool.js";
import { Dialog } from "element-ui";
import changePasswordAlert from "@c/modify-password/change-password-alert.vue";
import weakPasswordAlert from "@c/modify-password/weak-password-alert.vue";
export default {
  components: {
    MainLeftBar,
    MainRightTopBar,
    MainRightMiddleBar,
    Home: () => import("@c/home/home.vue"),
    MonitorCenter: () => import("@c/monitor-center/monitor-center.vue"),
    DeviceBorrow: () => import("@c/device-borrow/main.vue"),
    SleepReport: () => import("@c/report/report.vue"),
    UserManage: () => import("@c/user-manage/user-manage.vue"),
    DeviceManage: () => import("@c/device-manage/device-manage.vue"),
    Questionnaire: () => import("@c/screening-questionnaire/screening-questionnaire.vue"), //问卷筛查
    OperationLog: () => import("@c/operation-log/operation-log.vue"),
    Setting: () => import("@c/setting/setting.vue"),
    Manage: () => import("@c/manage/manage.vue"),
    elDialog: Dialog,
    changePasswordAlert,
    weakPasswordAlert,
  },

  data: function () {
    return {
      isSearchName: 0,
      searchName: '',
      selectedModuleIndex: 0,
      isMainLeftSlim: false,
      isShowChangePasswordAlert: false,
      isShowWeakPasswordAlert: false,
    };
  },

  computed: {
    moduleArray() {
      let rawModuleArray = [
        {
          title: "首页",
          name: "Home",
          icon: require("@imgs/home.png"),
          selectedIcon: require("@imgs/home-white.png"),
        },
        {
          title: "监测中心",
          name: "MonitorCenter",
          icon: require("@imgs/dashboard.png"),
          selectedIcon: require("@imgs/dashboard-white.png"),
        },
        {
          title: "设备借还",
          name: "DeviceBorrow",
          icon: require("@imgs/recycle.png"),
          selectedIcon: require("@imgs/recycle-white.png"),
        },
        {
          title: "睡眠报告",
          name: "SleepReport",
          icon: require("@imgs/invoices.png"),
          selectedIcon: require("@imgs/invoices-white.png"),
        },
        {
          title: "用户管理",
          name: "UserManage",
          icon: require("@imgs/customers.png"),
          selectedIcon: require("@imgs/customers-white.png"),
        },
        {
          title: "问卷筛查",
          name: "Questionnaire",
          icon: require("@imgs/screening-questionnaire.png"),
          selectedIcon: require("@imgs/screening-questionnaire-white.png"),
        },
        {
          title: "设备管理",
          name: "DeviceManage",
          icon: require("@imgs/device.png"),
          selectedIcon: require("@imgs/device-white.png"),
        },
        {
          title: "日志管理",
          name: "OperationLog",
          icon: require("@imgs/operation-log.png"),
          selectedIcon: require("@imgs/operation-log-white.png"),
        },
        {
          title: "设置",
          name: "Setting",
          icon: require("@imgs/setting-gray.png"),
          selectedIcon: require("@imgs/setting-white.png"),
        }
      ]
      if (this.$store.getters.isSeniorAdministrator) {
        rawModuleArray.push(
          {
            title: "管理",
            name: "Manage",
            icon: require("@imgs/manage-gray.png"),
            selectedIcon: require("@imgs/manage-white.png"),
          })
      }
      return rawModuleArray
    },

    selectedHospital() {
      return this.$store.state.selectedHospital;
    },

    selectedModule() {
      return this.moduleArray[this.selectedModuleIndex];
    }
  },

  created: function () {
    this.onCheckValidPassword()
    this.onCheckValidPassword()
    this.isMainLeftSlim = this.$store.state.isMainLeftSlim;
    this.selectedModuleIndex = this.$store.state.selectedModuleIndex;
  },



  methods: {
    weakIsShowChanged(value) {
      this.isShowWeakPasswordAlert = value
      this.isShowChangePasswordAlert = true
    },
    changeIsShowChanged(value) {
      this.isShowChangePasswordAlert = value
    },
    isChangePassword(value) {

      this.$toast.showGreen("修改密码成功,请重新登录");
      this.isShowChangePasswordAlert = value
      CacheTool.removeLoginInfo();
      this.$store?.commit("removeAccountInfo");
      this.$store?.commit("saveIsMainLeftSlim", false);
      this.$store?.commit("saveSelectedModuleIndex", 0);
      this.$store?.commit("saveSelectedHospital", { name: "全部", hospitalid: null });
      this.$router.push("Login");
    },

    onCheckValidPassword: function (valid) {
      if (localStorage.getItem('validPassword') == "false") {
        this.isShowWeakPasswordAlert = true
      }

    },
    onModuleItemClick: function (index) {
      if (index == 8 && this.selectedHospital.hospitalid == null) {
        this.$toast.showRed(MSG.pickHospitalRemind);
        return;
      }
      this.selectedModuleIndex = index;
      this.$store.commit("saveSelectedModuleIndex", index);
    },

    onDisplayIconClick: function () {

      this.isMainLeftSlim = !this.isMainLeftSlim;
      this.$store.commit("saveIsMainLeftSlim", this.isMainLeftSlim);
    },

    onChangePasswordClick: function () {
      //修改密码
      this.isShowChangePasswordAlert = true
    },

    async onLogoutClick() {
      try {
        await this.$api.logout({});
        CacheTool.removeLoginInfo();
        this.$store?.commit("removeAccountInfo");
        this.$store?.commit("saveIsMainLeftSlim", false);
        this.$store?.commit("saveSelectedModuleIndex", 0);
        this.$store?.commit("saveSelectedHospital", { name: "全部", hospitalid: null });
        this.$router.push("Login");
      } catch (error) {
        this.$toast.loginShowRed(error);
      }

    },

    //device-borrow
    onRecycleSuccessEditClick: function () {

      this.onModuleItemClick(6);
    },

    // device-manage
    onDeviceManageAddUserClick: function (deviceModel) {

      this.deviceInfoRequest(deviceModel, DEVICE_HANDLE_TYPE_CODE.借出);
    },

    onDeviceManageRecycleDeviceClick: function (deviceModel) {

      this.deviceInfoRequest(deviceModel, DEVICE_HANDLE_TYPE_CODE.回收);
    },

    //home
    onHomeAddUserClick() {
      this.onModuleItemClick(4);
    },

    onHomeRecycleDeviceClick() {
      this.onModuleItemClick(2);
    },

    async deviceInfoRequest(deviceModel, handleType) {
      try {
        const params = {
          id: deviceModel.id,
        }
        const response = await this.$api.getDeviceInfo(params)
        this.$store.commit("saveDeviceInfo", {
          deviceModel: response,
          handleType: handleType,
        });
        this.onModuleItemClick(2);
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &-dialog {

    ::v-deep .el-dialog__body {
      padding: 0px;
    }

    ::v-deep .el-dialog__header {
      padding: 0px;
      display: none;
    }
  }

  &-right {
    flex: 1;
    overflow-x: scroll;
    background-color: #EFF2F7;

    &-container {
      min-width: rem(1100);

      &-bottom {
        background-color: #f0f2f5;
        width: 100%;
        height: calc(100vh - 124px);
        overflow: hidden;
        overflow-y: scroll;
        box-sizing: border-box;
        padding: 20px;

        &-content {
          min-height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>