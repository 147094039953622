<template>
  <div id="app">
    <router-view />
    <Loading v-if="this.$store.state.loadingShow" />
  </div>
</template>

<script>
import { CacheTool } from "@js/cache-tool.js";
import { global } from "@js/global.js";
import Loading from "@c/common/Loading.vue";

// import VConsole from 'vconsole';

export default {
  components:{Loading},
  //在页面刷新时读取localStorage里的storeState信息
  created() {
    const storeState = CacheTool.getStoreState()
    if (storeState) {
      this.$store.replaceState(
        Object.assign(this.$store.state, CacheTool.getStoreState())
      );
    } 
  },

  //浏览器同时只能允许一个账号在线
  mounted() {
    // const vConsole = new VConsole();
    global.token = CacheTool.getLoginInfo()?.token
    document.addEventListener("visibilitychange", this.checkNameExpired)
  },
  
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.checkNameExpired)
  },

  methods: {
    checkNameExpired() {
      if (document.hidden == false && global.token != CacheTool.getLoginInfo()?.token) {
        global.token = CacheTool.getLoginInfo()?.token
        window.location.reload()
      }
    }
  },
  


};
</script>


<style lang="scss" scoped>

@media screen and (max-width: rem(450)) {
  #app {
    zoom: 70%;
  }
}
</style>


