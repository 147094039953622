import Vue from "vue";
import VueRouter from "vue-router";

import Main from "../views/Main.vue";
import { CacheTool } from "@js/cache-tool.js";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/Login.vue")
    },
    {
      path: "/",
      name: "Main",
      component: Main
    },
    {
      path: "/report",
      name: "Report",
      component: () => import("../views/Report/Report.vue")
    },
    {
      path: "/blood-oxygen-report",
      name: "BloodOxygenReport",
      component: () => import("@c/report-detail/single-blood-oxygen-report/blood-oxygen-report.vue")
    },
    {
      path: "/report-sheng-fu-bao",
      name: "ReportShengFuBao",
      component: () => import("../views/Report/ReportShengFuBao.vue")
    },
    {
      path: "/query-result",
      name: "QueryResult",
      component: () => import("../views/QueryResult/QueryResult.vue")
    }
  ]
});

router.beforeEach((to, from, next) => {
  const loginInfo = CacheTool.getLoginInfo();
  if (to.name === "Report" || to.name === "BloodOxygenReport") {
    // 如果要跳转详情，直接进入
    next()
  } else if (loginInfo || to.name === "Login") {
    // 如果存在登录信息,或者不存在登录信息，但进入的页面是登录页面,则直接进入
    next();
  } else {
    // 如果不存在登录信息,则跳转到登录页面.要带上当前的页面地址，方便登录完成后做回跳
    next({
      name: "Login"
    });
  }

});

export default router;
