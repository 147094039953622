import Toast from '@c/common/toast.vue';
 
const obj = {};
 
obj.install = function(Vue){
    const toastContrustor = Vue.extend(Toast); //组件构造器
    const toast = new toastContrustor(); //组件对象
    toast.$mount(document.createElement('div')) //全局挂载
    document.body.appendChild(toast.$el) //div全局添加
    Vue.prototype.$toast = toast; 
}
 
export default obj;