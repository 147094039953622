<template>
  <div class="main-right-middle-bar">
    <p class="main-right-middle-bar-text">
      首页<span class="main-right-middle-bar-slash"> / </span>
      {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>

.main-right-middle-bar {
  background-color: #f5f7fa;
  width: 100%;
  height: rem(54);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: rem(32);

  &-text {
    color: #909399;
    font-size: rem(14);
  }

  &-slash {
    color: #dcdfe6;
    font-size: rem(14);
    margin-left: rem(12);
    margin-right: rem(8);
  }
}
</style>