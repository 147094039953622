import Alert from "@c/common/alert.vue";

const obj = {};

obj.install = function(Vue) {
  const alertContrustor = Vue.extend(Alert); //组件构造器
  const alert = new alertContrustor(); //组件对象
  alert.$mount(document.createElement("div")); //全局挂载
  document.body.appendChild(alert.$el); //div全局添加
  Vue.prototype.$alert = alert;
};

export default obj;
