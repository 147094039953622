<template>
    <div class="loader">
        <!-- <div class="loading"> </div> -->
        <svg viewBox="25 25 50 50">
            <circle cx="50" cy="50" r="20"></circle>
        </svg>
    </div>
</template>
  
<script>

export default {
    data() {
        return {};
    },

    methods: {},

};
</script>
<style>
.loader {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 20;
}
/* .loading{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
  height: 48px;
  border: 3px solid transparent;
  border-top-color: #409EFF;
  border-bottom-color: #409EFF;
  border-radius: 50%;
  animation: spin-stretch 2s ease infinite;
}
@keyframes spin-stretch {
  50% {
    transform: rotate(360deg) scale(0.4, 0.33);
    border-width: 8px;
  }
  100% {
    transform: rotate(720deg) scale(1, 1);
    border-width: 3px;
  }
} */
svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 55px;
    height: 55px;
    transform-origin: center;
    animation: rotate 2s linear infinite;
}

circle {
    fill: none;
    stroke: #0D3F77;
    stroke-width: 2;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dashoffset: -125px;
    }
}
</style>