<template>
  <div class="main-right-top-bar ">
    <div class="main-right-top-bar-left">
      <button class="main-right-top-bar-left-display-icon" :style="{
        backgroundImage: `url(${[
          isMainLeftSlim
            ? require('@imgs/open.png')
            : require('@imgs/close.png'),
        ]})`,
      }" @click="onDisplayIconClick"></button>
      <div class="main-right-top-bar-left-hospital" @click="onHospitalClick">
        <p class="main-right-top-bar-left-hospital-name">{{ hospitalName }}</p>
        <img class="main-right-top-bar-left-hospital-icon" src="@imgs/main-hospital-switch.png">
      </div>
    </div>
    <div class="main-right-top-bar-right">
      <button class="main-right-top-bar-right-right" @mouseover="onRightTopRightMouseOver"
        @mouseleave="onRightTopRightMouseLeave">
        <img class="main-right-top-bar-right-right-avatar" src="@imgs/avatar.png" />
        <p class="main-right-top-bar-right-right-username"> {{ userName }} </p>
        <p class="triangle"></p>
        <div class="main-right-top-bar-right-right-menu" v-show="isShowLogout">
          <button class="main-right-top-bar-right-right-menu-change-password" @click="onChangePasswordClick">
            修改密码
          </button>
          <button class="main-right-top-bar-right-right-menu-logout" @click="onLogoutClick">
            退出登录
          </button>
        </div>
      </button>
    </div>
    <el-drawer :visible.sync="isShowDrawer" :with-header="false" destroy-on-close>
      <main-hospital-panel v-model="isShowDrawer" :selectedModuleIndex="selectedModuleIndex"></main-hospital-panel>
    </el-drawer>
    
  </div>
</template>

<script>
import PickList from "@c/common/pick-list.vue";
import MainHospitalPanel from "@c/main/main-hospital-panel.vue";
export default {
  components: {
    PickList,
    MainHospitalPanel
  },

  props: {
    isMainLeftSlim: Boolean,
    hospitalNameArray: Array,
    selectIndex: Number,
    isSearch: {
      type: Number,
      default: 0,
    },
    selectedModuleIndex:Number,
  },

  data() {
    return {
      isShowHospitalList: false,
      isShowLogout: false,
      hosptialListWidth: "",
      hosptialListLeft: "",
      userName: this.$store.state.accountInfo.trueName,
      isShowDrawer: false,
      
    };
  },

  computed: {
    hospitalName() {
      return this.$store.state.selectedHospital.name
    }
  },

  // updated: function () {
  // let $hospital = this.$refs.hospital;
  // if ($hospital.clientWidth >= 210) {
  //   this.hosptialListWidth = $hospital.clientWidth + "px";
  // } else {
  //   this.hosptialListWidth = 210 + "px";
  // }
  // this.hosptialListLeft = $hospital.clightLeft + "px";
  // },
  methods: {
    onSearchName() {
    
      this.$emit('onSearchName', this.searchName);

    },
    onDisplayIconClick() {
      this.isShowHospitalList = false;
      this.$emit("onDisplayIconClick");
    },

    onHospitalClick() {
      this.isShowDrawer = true

    },


    onHospitalMouseOver() {
      this.isShowHospitalList = true;
    },
    onHospitalMouseLeave() {
      this.isShowHospitalList = false;
    },
    onPickListItemClick: function (index) {
      this.isShowHospitalList = false;
      this.$emit("onPickListItemClick", index);
    },
    onRightTopRightMouseOver() {
      this.isShowLogout = true;
    },
    onRightTopRightMouseLeave() {
      this.isShowLogout = false;
    },
    onChangePasswordClick() {
      this.$emit("onChangePasswordClick");
    },
    onLogoutClick() {
      this.$emit("onLogoutClick");
    },
  },


  watch: {
    isSearch(newValue) {
      
      if (newValue> 1) {
        this.isShowHospitalList = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>


.main-right-top-bar {
  background-color: white;
  width: 100%;
  height: rem(70);
  box-shadow: rem(0) rem(1) rem(4) rem(0) rgba(0, 21, 41, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  

  &-left {
    height: 100%;
    display: flex;
    margin-left: rem(32);
    align-items: center;

    &-display-icon {
      width: rem(20);
      height: rem(18);
      background-size: rem(20) rem(18);
      background-repeat: no-repeat;
    }

    &-hospital {
      background-color: #F7F7F7;
      border-radius: rem(6);
      box-sizing: border-box;
      padding: rem(10) rem(20);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: rem(14);

      &-name {
        color: #325FAC;
        font-size: rem(18);
        font-weight: bold;
        margin-right: rem(15);
      }

      &-icon {
        width: rem(21);
        height: rem(18);
      }
    }
  }

  &-right {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: rem(41);

    &-button {
      color: #325FAC;
      font-size: rem(16);
      text-decoration: none;
      margin-right: rem(40);
    }

    &-right {
      height: 100%;
      display: flex;
      align-items: center;

      &-avatar {
        width: rem(24);
        height: rem(24);
        margin-right: rem(10);
      }

      &-username {
        color: rgba($color: black, $alpha: 0.65);
        font-size: rem(14);
        margin-right: rem(8);
      }

      &-menu {
        z-index: 50;
        background-color: white;
        color: rgba(black, 0.65);
        font-size: rem(14);
        box-shadow: rem(0) rem(3) rem(8) rem(0) rgba($color: black, $alpha: 0.15);
        border-radius: rem(4);
        width: rem(96);
        height: rem(80);
        position: absolute;
        top: rem(60);
        right: rem(31);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}
</style>