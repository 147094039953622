var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-left-bar",class:[_vm.isMainLeftSlim ? 'main-left-bar-slim' : 'main-left-bar-normal']},[_c('div',{staticClass:"main-left-bar-top"},[_c('img',{staticClass:"main-left-bar-top-logo",attrs:{"src":require("@imgs/logo-bajie-circle.png")}}),_c('p',{staticClass:"main-left-bar-top-name",class:[
        _vm.isMainLeftSlim
          ? 'main-left-bar-text-clear'
          : 'main-left-bar-text-opacity',
      ]},[_vm._v(" 八戒睡眠 ")])]),_c('div',{staticClass:"main-left-bar-list"},_vm._l((_vm.moduleArray),function(item,index){return _c('button',{key:index,staticClass:"main-left-bar-list-item",class:{
        'main-left-bar-list-item-h': index === _vm.selectedModuleIndex,
      },on:{"click":function($event){return _vm.onModuleItemClick(index)}}},[_c('img',{staticClass:"main-left-bar-list-item-icon",attrs:{"src":[index === _vm.selectedModuleIndex ? item.selectedIcon : item.icon]}}),_c('p',{staticClass:"main-left-bar-list-item-title",class:[
          _vm.isMainLeftSlim
            ? 'main-left-bar-text-clear'
            : 'main-left-bar-text-opacity',
        ]},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }