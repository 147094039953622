<template>
  <div
    class="main-left-bar"
    :class="[isMainLeftSlim ? 'main-left-bar-slim' : 'main-left-bar-normal']"
  >
    <div class="main-left-bar-top">
      <img class="main-left-bar-top-logo" src="@imgs/logo-bajie-circle.png" />
      <p
        class="main-left-bar-top-name"
        :class="[
          isMainLeftSlim
            ? 'main-left-bar-text-clear'
            : 'main-left-bar-text-opacity',
        ]"
      >
        八戒睡眠
      </p>
    </div>
    <div class="main-left-bar-list">
      <button
        class="main-left-bar-list-item"
        :class="{
          'main-left-bar-list-item-h': index === selectedModuleIndex,
        }"
        v-for="(item, index) in moduleArray"
        :key="index"
        @click="onModuleItemClick(index)"
      >
        <img
          :src="[index === selectedModuleIndex ? item.selectedIcon : item.icon]"
          class="main-left-bar-list-item-icon"
        />
        <p
          class="main-left-bar-list-item-title"
          :class="[
            isMainLeftSlim
              ? 'main-left-bar-text-clear'
              : 'main-left-bar-text-opacity',
          ]"
        >
          {{ item.title }}
        </p>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMainLeftSlim: Boolean,
    moduleArray: Array,
    selectedModuleIndex: Number,
  },

  methods: {
    onModuleItemClick(index) {
      this.$emit("onModuleItemClick", index);
    },
  },
};
</script>

<style lang="scss" scoped>

.main-left-bar {
  background-color: #0D3F77;
  // height: 100vh;
  flex-direction: column;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }

  &-slim {
    transition: width $animation-duration;
    width: rem(64);
  }

  &-normal {
    transition: width $animation-duration;
    width: rem(256);
  }

  &-text-clear {
    transition: opacity $animation-duration;
    opacity: 0;
  }

  &-text-opacity {
    transition: opacity $animation-duration;
    opacity: 1;
  }

  &-top {
    width: 100%;
    height: rem(70);
    display: flex;
    align-items: center;

    &-logo {
      width: rem(23);
      height: rem(22);
      margin-left: rem(24);
    }

    &-name {
      color: #FFFFFF;
      font-size: rem(16);
      margin-left: rem(15);
    }
  }

  &-list {
    width: 100%;

    &-item {
      width: 100%;
      height: rem(70);
      display: flex;
      align-items: center;

      &:hover {
        background-color: #105096;
      }

      &-h {
        background-color: #325FAC;

        &:hover {
          background-color: #325FAC;
        }
      }

      &-icon {
        width: rem(16);
        height: auto;
        margin-left: rem(22);
      }

      &-title {
        color: white;
        font-size: rem(14);
        margin-left: rem(10);
      }
    }
  }
}
</style>