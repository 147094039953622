<template>
  <transition name="fold">
    <div class="pick-list z-index-third" v-if="isShow">
      <ul class="pick-list-container">
        <button
          class="pick-list-container-item"
          :class="[
            index === selectedIndex
              ? 'pick-list-container-item-selected'
              : 'pick-list-container-item-normal',
          ]"
          v-for="(item, index) of pickArray"
          :key="index"
          @click="onListItemClick(index)"
        >
          <p class="pick-list-container-item-name">{{ item }}</p>
        </button>
      </ul>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    pickArray: {
      type: Array,
      default: [],
    },

    isShow: {
      type: Boolean,
      default: false,
    },

    selectedIndex: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    onListItemClick: function (index) {
      this.$emit("onListItemClick", index);
    },
  },
};
</script>

<style lang="scss" scoped>


.pick-list {
  background-color: white;
  width: 100%;

  &-container {
    :last-child {
      border-bottom: none;
      border-bottom-left-radius: rem(4);
      border-bottom-right-radius: rem(4);
    }
    width: 100%;
    max-height: rem(330);
    overflow-y: scroll;
    box-shadow: rem(0) rem(3) rem(8) rem(0) rgba($color: black, $alpha: 0.15);
    border-radius: rem(4);

    &-item {
      border-bottom: 1px solid rgba($color: black, $alpha: 0.09);
      width: 100%;
      box-sizing: border-box;
      padding: rem(5) 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: #f7f7f7;
      }

      &-selected {
        background-color: #e6f7ff;

        &:hover {
          background-color: #e6f7ff;
        }
      }

      &-normal {
        background-color: white;
      }

      &-name {
        color: black;
        opacity: 0.65;
        text-align: left;
        font-size: rem(14);
        line-height: rem(22);
        margin-left: rem(15);
      }
    }
  }
}

//动画
.fold-enter-active {
  animation-duration: $animation-duration;
  animation-name: fold-height-enter;
}
@keyframes fold-height-enter {
  0% {
    max-height: 0;
    opacity: 0;
  }

  100% {
    max-height: rem(32);
    opacity: 1;
  }
}
.fold-leave-active {
  animation-duration: $animation-duration;
  animation-name: fold-height-leave;
}
@keyframes fold-height-leave {
  0% {
    max-height: rem(32);
    opacity: 1;
  }

  100% {
    max-height: 0;
    opacity: 0;
  }
}
</style>