import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@css/reset.css";

import "@js/constants.js";
import "@js/filters.js";
import "@js/cache-tool.js";
import "@js/api.js"

//toast组件
import toast from "@js/mount-toast.js";
Vue.use(toast); //为每一个vue对象添加一个$toast组件($标记便于分辨)

//alert组件
import alert from "@js/mount-alert.js";
Vue.use(alert); //为每一个vue对象添加一个$alert组件($标记便于分辨)

//element-ui
import { Drawer, DatePicker, TimeSelect, Select, Option, Input, Transfer, Switch, Pagination, Popover, Table, TableColumn } from "element-ui";
Vue.component(Drawer.name, Drawer)
Vue.component(DatePicker.name, DatePicker)
Vue.component(TimeSelect.name, TimeSelect)
Vue.component(Switch.name, Switch)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Input.name, Input)
Vue.component(Transfer.name, Transfer)
Vue.component(Pagination.name, Pagination)
Vue.component(Popover.name, Popover)
Vue.component(Table.name, Table)
Vue.component(TableColumn.name,TableColumn)

//echarts
let echarts = require('echarts/lib/echarts')
import { LineChart, BarChart, PieChart } from 'echarts/charts';
echarts.use([LineChart, BarChart, PieChart]);
import { GridComponent, LegendComponent, TooltipComponent, DataZoomComponent, MarkLineComponent } from 'echarts/components';
echarts.use([GridComponent, LegendComponent, TooltipComponent, DataZoomComponent, MarkLineComponent]);
Vue.prototype.$echarts = echarts

//剪切板
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false;

//导出供js页面使用
const vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

export default vue
