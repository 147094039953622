
class CacheTool {
  //login
  static saveLoginInfo(loginInfo) {
    localStorage.setItem(CACHE_KEY.loginInfo, JSON.stringify(loginInfo));
  }

  static removeLoginInfo() {
    localStorage.removeItem(CACHE_KEY.loginInfo);
  }

  static getLoginInfo() {
    return JSON.parse(localStorage.getItem(CACHE_KEY.loginInfo));
  }

  //storeState
  static saveStoreState(storeState) {
    localStorage.setItem(CACHE_KEY.storeState, JSON.stringify(storeState));
  }

  static removeStoreState() {
    localStorage.removeItem(CACHE_KEY.storeState);
  }

  static getStoreState() {
    return JSON.parse(localStorage.getItem(CACHE_KEY.storeState));
  }
  
}

export { CacheTool };
